(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:InvoiceCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('InvoiceCtrl', InvoiceCtrl);

  function InvoiceCtrl(company,
    user,
    _,
    $state,
    FormControl,
    Auth,
    $location,
    cartOrder,
    $q,
    isAuthenticated,
    LoggedUser,
    orderType,
    OrderService,
    $analytics,
    $translate,
    wallets) {
    var vm = this
      , formControl = new FormControl()
      , userToAuthenticate = {}
      , deferredFormControl
      , EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
      , selectedProductType = localStorage.getItem(EdenredECSselectedProductType);

    vm.prevState = isAuthenticated ? 'typeSelector' : 'contract';
    vm.cartOrder = cartOrder;
    vm.company = company;
    vm.user = user;
    vm.wallets = wallets;
    vm.invoiceForm = {};
    vm.orderType = orderType;
    vm.analyticsOrder = {};

    vm.handlingFeePercent = _.isUndefined(vm.company.handlingFeePercent) ? 3 : vm.company.handlingFeePercent;
    vm.cardFee = _.isUndefined(vm.company.cardFee) ? 1000 : vm.company.cardFee;

    vm.isRequestOrderType = function isRequestOrderType() {
      return orderType === '1';
    };

    vm.isMultiWalletCardSelected = function isMultiWalletCardSelected() {
      return selectedProductType === 'multiWallet';
    };

    vm.getTotalQuantityByWallet = function getTotalQuantityByWallet(type) {
      return _.filter(vm.cartOrder.items, function (item) {
        return item[type] > 0;
      }).length;
    };

    vm.getTotalAmountByWallet = function getTotalAmountByWallet(type) {
      return _.reduce(vm.cartOrder.items, function (sum, item) {
        return sum + item[type];
      }, 0);
    };

    vm.shouldShowActivationAlert = function shouldShowActivationAlert() {
      return selectedProductType !== 'comfort' && orderType === '1';
    };

    vm.hasCompanyDeliveryAddress = function hasCompanyDeliveryAddress() {
      return vm.company.deliveryAddress.zipCode && vm.company.deliveryAddress.city && vm.company.deliveryAddress.address;
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var analyticsDatas = {}
        , action = '';

      if (!isAuthenticated) {
        action = $translate.instant('order.steps.analytics.invoice.anonymous') + ' - ' + $translate.instant('order.steps.analytics.ended');

        analyticsDatas = {
          category: $translate.instant('order.steps.analytics.registration'),
          action: action,
          label: company.vatNumber
        };
        $analytics.eventTrack(action, analyticsDatas);
      } else {
        action = $translate.instant('order.steps.analytics.invoice.authenticated') + ' - ' + $translate.instant('order.steps.analytics.ended');

        analyticsDatas = {
          category: $translate.instant('order.steps.analytics.registration.' + orderType),
          action: action,
          label: company.vatNumber
        };
        $analytics.eventTrack(action, analyticsDatas);
      }

      deferredFormControl = $q.defer();

      OrderService.setInProgress();

      formControl
        .process(deferredFormControl.promise)
        .catch(deferredFormControl.reject)
        .finally(OrderService.clearInProgress);

      processOrder();
    };

    function processOrder() {
      if (isAuthenticated) {
        processAuthenticatedOrder();
      } else {
        processAnonymousOrder();
      }
    }

    function processAuthenticatedOrder() {
      var OrderForAuthenticatedUser = company.getOrder();

      cartOrder = _.defaultsDeep(new OrderForAuthenticatedUser(), cartOrder);
      cartOrder
        .$save(deferredFormControl.resolve, deferredFormControl.reject)
        .then(function (result) {
          vm.analyticsOrder.id = result.data.id;
        })
        .then(sendEcommerceEvent)
        .then(function () {
          setSucccessStateDatas();
          OrderService.updateCart();

          $state.go('success', { type: $translate.instant('order.type.' + orderType + '.url') });
        });
    }

    function processAnonymousOrder() {
      userToAuthenticate = {
        identifier: user.email,
        password: user.password
      };

      user
        .$save()
        .then(silentLogin)
        .then(saveCompany)
        .then(setCompany)
        .then(saveOrder)
        .then(silentlogout)
        .then(normalLogin)
        .then(restoreCompanySession)
        .then(setSucccessStateDatas)
        .then(sendEcommerceEvent)
        .then(sendFacebookPixelEvent)
        .then(OrderService.updateCart)
        .catch(deferredFormControl.reject);
    }

    function sendEcommerceEvent() {
      vm.analyticsOrder.affiliation = vm.company.vatNumber;
      vm.analyticsOrder.revenue = vm.cartOrder.itemTotalNet + vm.cartOrder.handlingFeeNet + vm.cartOrder.cardFeeNet;
      vm.analyticsOrder.shipping = vm.cartOrder.deliveryFeeNet;
      vm.analyticsOrder.tax = (vm.cartOrder.itemTotalGross + vm.cartOrder.handlingFeeGross + vm.cartOrder.cardFeeGross) - (vm.cartOrder.itemTotalNet + vm.cartOrder.handlingFeeNet + vm.cartOrder.cardFeeNet);
      vm.analyticsOrder.products = [];

      if (vm.cartOrder.items && vm.cartOrder.items.length > 0) {
        _.forEach(vm.cartOrder.items, function (item) {
          var analyticsOrderItem = {
            name: $translate.instant('order.invoice.cardType.' + item.cardTypeId + '.title'),
            id: item.cardTypeId,
            price: item.loadAmount,
            quantity: item.quantity,
            currency: 'HUF'
          };
          vm.analyticsOrder.products.push(analyticsOrderItem);
        });
      }

      $analytics.transactionTrack(vm.analyticsOrder);
    }

    function silentLogin(result) {
      vm.analyticsOrder.id = result.id;
      return Auth.silentLogin(userToAuthenticate);
    }

    function saveCompany() {
      var UserCompany = user.getCompany();

      company.userId = user.id;
      company = _.defaultsDeep(new UserCompany(), company);
      return company.$save();
    }

    function setCompany() {
      return LoggedUser.setCompany(company.id);
    }

    function saveOrder() {
      var CompanyOrder = company.getOrder();

      cartOrder.userId = user.id;
      cartOrder.companyId = company.id;
      cartOrder = _.defaultsDeep(new CompanyOrder(), cartOrder);
      return cartOrder.$save();
    }

    function silentlogout() {
      return Auth.silentlogout();
    }

    function normalLogin() {
      var orderTypeName = $translate.instant('order.type.' + orderType + '.url');

      deferredFormControl.resolve();
      $location.setNextUrl('order-with-contract/' + orderTypeName + '/success');
      return Auth.login(userToAuthenticate);
    }

    function setSucccessStateDatas() {
      OrderService.setSuccessData({
        isAuthenticated: isAuthenticated,
        orderType: orderType
      });
    }

    function restoreCompanySession() {
      var UserCompany = user.getCompany()
        , userActiveCompany = LoggedUser.getCompany();

      UserCompany.get({ companyId: userActiveCompany.id });
    }

    function sendFacebookPixelEvent() {
      if (_.isEmpty(_.get(window, 'fbq.instance.pixelsByID', {}))) {
        return;
      }

      window.fbq('track', 'CompleteRegistration');
    }
  }
}());
